import React, { useState } from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import ProductGrid from '../../CategoryPage/ProductGrid';
import productRowQuery from './ProductRowQuery.gql';
import productRowWithCatQuery from './ProductRowWithCatQuery.gql';
import ProductCard from '../../CategoryPage/ProductCard';
import SlickSlider from '../../ui/SlickSlider';
import t from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';
import { Favourite } from '../../ProductList/Favourite';
import LipscoreApiCall from '../../ProductPage/Lipscore/LipscoreApiCall';
import { useInView } from 'react-intersection-observer';

const backupId = 148;

const ProductRowOuterWrapper = styled('div')`
  width: 100%;
  [data-visible='false'] > div {
    padding-top: 500px;
  }
`;

const ProductRowWrapper = styled('div')`
  width: 100%;
  > h3 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

//const ProductRowTitle = styled(ProductListHeader)``;

export const RowProducts = ({
  category,
  slider,
  title,
  page,
  children,
  ...rest
}) => {
  const amount = category?.products?.result.length;
  if (amount < 3) slider = false;
  return (
    <ProductRowWrapper>
      {title && <h3>{t(title)}</h3>}
      {slider ? (
        <LipscoreApiCall products={category.products.result}>
          {productsWithRating => {
            return (
              <Above breakpoint="md">
                {matches => {
                  let settings = {
                    dots: false,
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 2
                  };
                  if (matches && amount >= 4) {
                    settings.slidesToShow = 4;
                    settings.slidesToScroll = 4;
                  }

                  return (
                    <SlickSlider
                      settings={settings}
                      className="product-row-slider"
                    >
                      {productsWithRating.map((product, index) => {
                        //GET RATING FROM RATINGS OBJECT
                        //const rating =
                        //ratings[product.id] && ratings[product.id].rating;
                        return (
                          <div
                            key={category.id + '-' + product.id}
                            onClick={rest.clickEvent}
                          >
                            <ProductCard
                              displayColorCircles={true}
                              product={product}
                              categoryPath={null}
                              combineSecondTooltip={true}
                            >
                              <Favourite
                                product={product}
                                style={{
                                  position: 'absolute',
                                  padding: '0.5em',
                                  fontSize: '1.5em',
                                  top: 0,
                                  right: 0,
                                  zIndex: '2'
                                }}
                              />
                            </ProductCard>
                          </div>
                        );
                      })}
                    </SlickSlider>
                  );
                }}
              </Above>
            );
          }}
        </LipscoreApiCall>
      ) : (
        <ProductGrid
          products={category.products.result}
          listName={category.name}
          categoryPath={null}
          {...rest}
        />
      )}
    </ProductRowWrapper>
  );
};
const ProductRow = ({
  id,
  limit = 6,
  orderBy = null,
  orderByDirection = 'DESCENDING',
  slider = false,
  showCat = false,
  page,
  title = '',
  ssr = false,
  buyableId = '',
  ...rest
}) => {
  const [showed, setShowed] = useState(false);
  const [ref, inView] = useInView();
  if (inView && !showed) setShowed(true);

  //QUERY WITH CAT INFO OR NOT
  const rowQuery = showCat ? productRowWithCatQuery : productRowQuery;
  return (
    <ProductRowOuterWrapper data-visible={showed}>
      <div ref={ref}>
        {showed && (
          <Query
            query={rowQuery}
            ssr={ssr}
            variables={{
              id: parseInt(id, 10),
              first: parseInt(limit, 10),
              orderBy: orderBy,
              orderByDirection: orderByDirection,
              buyableId: buyableId
            }}
          >
            {result => {
              const category = result && result.data && result.data.category;
              if (category) {
                if (category.products && category.products.result.length > 0)
                  return (
                    <RowProducts
                      category={category}
                      slider={slider}
                      title={title}
                      {...rest}
                    />
                  );
                else if (id !== backupId) {
                  return (
                    <ProductRow
                      id={backupId}
                      limit={limit}
                      orderBy={orderBy}
                      orderByDirection={orderByDirection}
                      slider={slider}
                      title={title}
                      {...rest}
                    />
                  );
                } else return null;
              } else return null;
            }}
          </Query>
        )}
      </div>
    </ProductRowOuterWrapper>
  );
};

export default ProductRow;
