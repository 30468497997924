import React from 'react';

const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};

const compareArray = (a, b) => {
  // if length is not equal
  if (a.length !== b.length) return false;

  // comapring each element of array
  for (var i = 0; i < a.length; i++) if (a[i] !== b[i]) return false;
  return true;
};

class LipscoreApiCall extends React.Component {
  state = {
    ratings: null,
    hasErrors: false
  };
  _isMounted = false;

  fetchData(products) {
    if (products && products.length > 0) {
      let requestString = '';
      for (let i = 0; i < products.length; i++) {
        let item = products[i].id;
        requestString = requestString.concat('&internal_id[]=' + item);
      }
      const apiKey = '7348ae9c301fd8646b6618e7';
      fetch(
        'https://api.lipscore.com/products?api_key=' +
          apiKey +
          requestString +
          '&fields=rating,votes'
      )
        .then(response => response.json())
        .then(data => {
          if (this._isMounted) {
            const ratingsObject = convertArrayToObject(data, 'internal_id');
            this.setState({ ratings: ratingsObject });
          }
        })
        .catch(() => this.setState({ hasErrors: true }));
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetchData(this.props.products);
  }

  componentDidUpdate(prevProps) {
    if (!compareArray(prevProps.products, this.props.products)) {
      this.fetchData(this.props.products);
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { children } = this.props;
    const ratings = this.state.ratings;
    const products = this.props.products;
    if (ratings) {
      let productsWithRating = [];
      for (var i = 0; i < products.length; i++) {
        const product = products[i];
        productsWithRating.push({
          ...product,
          rating: ratings[product.id] && ratings[product.id].rating
        });
      }
      return children(productsWithRating);
    }
    return children(products);
  }
}

export default LipscoreApiCall;
