import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { ProductCard } from './ProductCard';
import { theme } from '../Theming/Theme';
import { Favourite } from '../ProductList/Favourite';
import LipscoreApiCall from '../ProductPage/Lipscore/LipscoreApiCall';

export const priceStyle = `
  [data-flight-price] {
    display: flex;
  }
  .new-price {
    color: #ff0000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .old-price {
    color: ${theme.colors.grey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;

  ${priceStyle};

  .product-card {
    line-height: 19px;
    min-width: 0;

    width: 50%;
    ${theme.above.md} {
      width: 33.333%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
  }
`;

export function ProductGrid({
  products,
  listName,
  loading,
  className,
  page,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  if (!products) return null;

  return (
    <LipscoreApiCall products={products}>
      {productsWithRatings => {
        return (
          <Wrapper
            data-testid="product-grid"
            className={cx('product-grid', className)}
          >
            {productsWithRatings.map((product, index) => {
              return (
                <ProductCard
                  key={index + ':' + product.articleNumber}
                  product={product}
                  displayColorCircles={true}
                  style={{ opacity: loading ? 0.5 : 1 }}
                  list={listName}
                  {...rest}
                >
                  <Favourite
                    product={product}
                    style={{
                      position: 'absolute',
                      padding: '0.5em',
                      fontSize: '1.5em',
                      top: 0,
                      right: 0,
                      zIndex: '2'
                    }}
                  />
                </ProductCard>
              );
            })}
          </Wrapper>
        );
      }}
    </LipscoreApiCall>
  );
}

export default ProductGrid;
